<template>
  <div :class="['Alerts', noHeader?'_noHeader':'',]" @mouseenter="expandPrimary()" @mouseleave="collapsePrimary()">
    <span
      aria-live="polite"
      ref="primaryAlertContainer"
      id="primaryAlert"
      v-if="primaryAlert.value"
      :class="['Alerts_Primary', noHeader?'_noHeader':'', `_${primaryAlert.value.type}`, primaryOpen?'_open':'', primaryAlert.value.code?'_coded':'', primaryExpanded?'_expanded':'']">
      <SROnly>
        <template v-if="primaryAlert.value.type=='info'">Information alert</template>
        <template v-if="primaryAlert.value.type=='error'">Error alert</template>
        <template v-if="primaryAlert.value.type=='warn'">Warning alert</template>
        <template v-if="primaryAlert.value.type=='success'">Success alert</template>
      </SROnly>
      <span class="Alerts_Primary_Title">{{ primaryAlert.value.title }}</span>
      <p v-if="primaryExpanded" v-html="primaryAlert.value.message" class="Alerts_Primary_Msg"></p>
      <span v-if="primaryAlert.value.code" class="Alerts_Primary_Code">{{ primaryAlert.value.code }}</span>
    </span>

    <div :class="['Alerts_List', noHeader?'_noHeader':'', primaryExpanded?'_open':'']" ref="alertContainer">
      <ul id="alerts" aria-live="polite" ref="alertList">
        <template v-for="(alert,index) in currentAlerts" :key="'alert-' + index">
          <li v-bind:class="['alert', `_${alert.type}`, primaryAlert.value&&index==primaryAlert.value.timestamp?'_hidden':'']" :ref="el => { renderedAlerts[alert.timestamp] = el }">
            <div class="alert-inner">
              <SROnly>
                <template v-if="alert.type=='info'">Information alert</template>
                <template v-if="alert.type=='error'">Error alert</template>
                <template v-if="alert.type=='warn'">Warning alert</template>
                <template v-if="alert.type=='success'">Success alert</template>
              </SROnly>
              <span class="Alerts_Primary_Title">{{ alert.title }}</span>
              <p class="Alerts_Primary_Msg" v-html="alert.message"></p>
              <span v-if="alert.code" class="Alerts_Primary_Code">{{ alert.code }}</span>
            </div>
            <!-- <Button class="close" type="icon" :icon="['solid', 'times']" @click="closeAlert(alert.timestamp)">Close</Button> -->
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import SROnly from '@/components/Helpers/SROnly';
import { inject, watch, reactive, ref, onBeforeUpdate, toRefs } from 'vue';

export default {
  name: 'AlertsPlugin',
  setup( props ) {
    const { noHeader } = toRefs( props );

    const primaryAlertContainer = ref();
    const alertContainer = ref( null );
    const alertList = ref( null );
    const renderedAlerts = ref( [] );
    const alertsList = inject( '$alertsList' );
    const currentAlerts = reactive( {} );
    const primaryAlert = reactive( {} );
    const primaryOpen = ref( false );
    const primaryExpanded = ref( false );

    let primePrimaryClose = false;
    let clearPrimary;

    const doClosePrimary = () => {
      if( noHeader.value ) {
        primaryExpanded.value = false;
      }
      primePrimaryClose = false;
      primaryOpen.value = false;
      setTimeout( () => {
        primaryAlert.value = null;
      }, 300 );
    };

    const closeAlert = timestamp => {
      if( renderedAlerts.value[timestamp] != 'undefined' ) { //already removed
        renderedAlerts.value[timestamp].classList.remove( 'open' );
        setTimeout( () => {
          delete currentAlerts[timestamp];
        }, 500 );
      }
    };
    const addAlert = data => {
      currentAlerts[data.timestamp] = data;
      primaryAlert.value = data;
      clearTimeout( clearPrimary );
      setTimeout( () => {
        for( const alert of alertList.value.children ) {
          alert.classList.add( 'open' );
        }
        primaryOpen.value = true;
        setTimeout( () => {
          if( noHeader.value ) {
            primaryExpanded.value = true;
          }
          setTimeout( () => {
            if( noHeader.value ) {
              const primaryHeight = primaryAlertContainer.value.offsetHeight;
              alertContainer.value.style.bottom = `${primaryHeight + 80}px`;
            }
          }, 100 );
        }, 300 );
      }, 100 );
      setTimeout( () => {
        closeAlert( data.timestamp );
      }, 10000 );
      clearPrimary = setTimeout( () => {
        if( primaryExpanded.value ) {
          primePrimaryClose = true;
        } else {
          doClosePrimary();
        }
      }, 10000 );
    };
    const doPop = () => {
      if( alertsList.value.length ) {
        const alert = alertsList.value.pop();
        addAlert( alert );
        if( alertsList.value.length ) {
          doPop();
        }
      }
    };

    let finalCollapse;
    const expandPrimary = () => {
      clearTimeout( finalCollapse );
      primaryExpanded.value = true;
      setTimeout( () => {
        const primaryHeight = primaryAlertContainer.value.offsetHeight;
        if( noHeader.value ) {
          alertContainer.value.style.bottom = `${primaryHeight + 80}px`;
        } else {
          alertContainer.value.style.top = `${primaryHeight}px`;
        }
      }, 100 );
    };
    const collapsePrimary = () => {
      if( !noHeader.value ) {
        primaryExpanded.value = false;
      }
      if( primePrimaryClose ) {
        finalCollapse = setTimeout( () => {
          doClosePrimary();
        }, 2000 );
      }
    };

    watch( alertsList.value, () => {
      doPop();
    } );

    onBeforeUpdate( () => {
      renderedAlerts.value = [];
    } );

    return {
      primaryAlertContainer,
      alertContainer,
      alertList,
      renderedAlerts,
      currentAlerts,
      primaryAlert,
      primaryOpen,
      primaryExpanded,
      expandPrimary,
      collapsePrimary,

      closeAlert,
    };
  },
  props: [ 'noHeader' ],
  components: {
    SROnly,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  // @keyframes bounce-coded {
  //   0% { height: 26px; }
  //   30% { height: 36px; }
  //   50% { height: 26px; }
  //   70% { height: 34px; }
  //   100% { height: 26px; }
  // }

  // @keyframes bounce {
  //   0% { height: 20px; }
  //   30% { height: 30px; }
  //   50% { height: 20px; }
  //   70% { height: 26px; }
  //   100% { height: 20px; }
  // }

  .Alerts {
    display: block;
    position: fixed;
    top: 15px;
    right: 56px;
    z-index: 9999999999999999999999999999999999999999999999999;

    &._noHeader {
      bottom: 0;
    }

    &_Primary {
      display: inline-block;
      position: absolute;
      right: 90px;
      top: 0;
      width: 0;
      height: 20px;
      padding: 9px 10px 7px;
      border-radius: 10px;
      overflow: hidden;
      background: transparent;
      transition: width 0.5s, height 0.3s, padding 0.3s, background 0.5s;
      color: #000;

      &._noHeader {
        bottom: 80px;
        right: 90px;
        top: unset;
      }

      &_Title {
        display: inline-block;
      }

      &_Msg {
        font-size: 0.8em;
      }

      &_Code {
        display: block;
        font-size: 0.6em;
        position: relative;
        top: -3px;
      }

      &._open {
        width: 230px;
        z-index: 999999999999999999999999999999999999999;
        // animation-name: bounce;
        // animation-duration: 1.5s;
      }

      &._coded {
        height: 26px;
        top: 0px;
        padding: 5px 10px;
        // &._open {
        //   animation-name: bounce-coded;
        //   animation-duration: 1.5s;
        // }
      }

      &._expanded {
        height: auto;
        border-radius: 10px;
      }

      &._info {
        background: #e5ebf2;
      }
      &._error {
        background: #e8a09b;
      }
      &._warn {
        background: #e8e1a3;
      }
      &._success {
        background: #91c993;
      }
    }

    &_List {
      display: none;
      position: absolute;
      right: 90px;
      width: 250px;
      top: 100px;

      &._noHeader {
        top: unset;
        bottom: 200px;
        right: 90px;
      }

      &._open {
        display: block;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: block;
            margin: 10px 0;
            padding: 9px 10px 7px;
            border-radius: 10px;
            overflow: hidden;
            background: transparent;
            transition: width 0.5s, height 0.3s, padding 0.3s, background 0.5s;
            color: #000;

            &._info {
              background: #e5ebf2;
            }
            &._error {
              background: #e8a09b;
            }
            &._warn {
              background: #e8e1a3;
            }
            &._success {
              background: #91c993;
            }

            &._hidden {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
